<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">Metselwerk</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Voeg- en steentype</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card outer-class="w-100" inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <div class="mb-4">
                <checkmark-item class="mb-2" icon="exclamation-circle-fill">
                  Meet nu de voeg op, zoals op de foto:
                </checkmark-item>
                <img src="/img/masonry/voeg-opmeten.jpg" alt="Voegbreedte meten" class="rounded shadow" />
            </div>

            <!-- inputs -->
            <base-input
              class="mt-5"
              type="radiobuttons" :options="jointSizeOptions" direction="column" text-align="left"
              name="Consent"
              v-model:value="lead.cavity_wall.masonry.JointSize" :validator="v$.cavity_wall.masonry.JointSize">
              Hoe breed is de voeg?
            </base-input>
            <issue-feedback :issue="qualStore.sections.cavity_wall.rules.JointSize" />

            <!-- type steen -->
            <base-input
              class="mt-5"
              type="buttongrid" :options="brickTypeOptions"
              name="BrickType"
              button-grid-input-type="radio"
              :min-col-width="100"
              :max-cols="1"
              btn-direction="row"
              :dim-label-on-value="false"
              :show-input="false"
              v-model:value="lead.cavity_wall.masonry.BrickType" :validator="v$.cavity_wall.masonry.BrickType">
              Welk type steen ziet u?
            </base-input>

            <!-- type voeg -->
            <base-input
              class="mt-5"
              type="buttongrid" :options="jointTypeOptions"
              name="JointType"
              button-grid-input-type="radio"
              :min-col-width="100"
              :max-cols="1"
              btn-direction="row"
              :dim-label-on-value="false"
              :show-input="false"
              v-model:value="lead.cavity_wall.masonry.JointType" :validator="v$.cavity_wall.masonry.JointType">
              Welk type voeg ziet u?
            </base-input>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.cavity_wall.masonry.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  /* eslint-disable */
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      const imageStyle = {
        width: '100px',
        height: '100px'
      };
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        jointSizeOptions: [
          {
            label: '< 14 mm',
            value: 'kleiner dan 14 mm'
          },
          {
            label: '14 mm - 18 mm',
            value: '14 mm - 18 mm'
          },
          {
            label: '18 mm - 20 mm',
            value: '18 mm - 20 mm'
          },
          {
            label: '20 mm >',
            value: '20 mm of groter'
          }
        ],
        brickTypeOptions: [
          {
            label: 'MBI betonsteen',
            value: 'MBI betonsteen',
            image: '/img/masonry/mbi-steen.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          },
          {
            label: 'Kalkzandsteen',
            value: 'Kalkzandsteen',
            image: '/img/masonry/kalkzandsteen.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          },
          {
            label: 'Geglazuurde steen',
            value: 'Geglazuurde steen',
            image: '/img/masonry/geglazuurde-steen.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          },
          {
            label: 'Geen van bovenstaande',
            value: 'Geen van bovenstaande',
            image: '',
            imageRounded: true,
            imageStyle: imageStyle
          }
        ],
        jointTypeOptions: [
          {
            label: 'Vlakke voeg',
            value: 'Vlakke voeg',
            image: '/img/masonry/vlakke-voeg.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          },
          {
            label: 'Knip-/snijvoeg',
            value: 'Knip-/snijvoeg',
            image: '/img/masonry/knip-snijvoeg.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          },
          {
            label: 'Diepliggende voeg',
            value: 'Diepliggende voeg',
            image: '/img/masonry/diepliggende-voeg.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          }
        ]
      };
    },
    computed: {

    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>