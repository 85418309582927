<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">Subsidieregelingen</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">NIP subsidie</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <div class="small mb-4">
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Het Nationaal Isolatieprogramma (NIP) ondersteunt gemeenten bij het isoleren van woningen. Onder bepaalde voorwaarden kunt u subsidie ontvangen.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  U mag bijvoorbeeld niet m&eacute;&eacute;r verdienen dan 125% van het sociaal minimum voor uw leefvorm.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill">
                  Middels deze vragen controleren we of u in aanmerking komt voor een aanvraag.
                </checkmark-item>
            </div>

            <!-- inputs -->
            <base-input
              type="radiobuttons" :options="leefvormOptions" text-align="left" direction="column"
              name="Consent"
              v-model:value="lead.financing.nip.Leefvorm" :validator="v$.financing.nip.Leefvorm">
              Wat is uw leefvorm?
            </base-input>

            <!-- card with 125% of social minimum -->
            <base-card inner-class="small">
              Voor uw situatie bedraagt 125% van het sociaal minimum:
              <div>
                <span class="font-mono">
                  &euro; {{ socialMinimum125 }}
                </span>
                per maand (bruto)
              </div>
            </base-card>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.financing.snnSubsidy.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  // import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      // IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        yesNoDontKnow: [
          {
            label: 'Ja',
            value: 'Ja'
          },
          {
            label: 'Nee',
            value: 'Nee'
          },
          {
            label: 'Weet ik niet',
            value: 'Weet ik niet'
          }
        ],
        leefvormOptions: [
          {
            label: 'Gepensioneerd - Alleenstaand',
            value: 'Gepensioneerd - Alleenstaand',
            socialMinimum: 0 // TODO
          },
          {
            label: 'Gepensioneerd - Gehuwd of samenwonend',
            value: 'Gepensioneerd - Gehuwd of samenwonend',
            socialMinimum: 0 // TODO
          },
          {
            label: '21 jaar tot pensioenleeftijd - Alleenstaand',
            value: '21 jaar tot pensioenleeftijd - Alleenstaand',
            socialMinimum: 1473.56
          },
          {
            label: '21 jaar tot pensioenleeftijd - Alleenstaande ouder',
            value: '21 jaar tot pensioenleeftijd - Alleenstaande ouder',
            socialMinimum: 0 // TODO
          },
          {
            label: '21 jaar tot pensioenleeftijd - Gehuwd of samenwonend',
            value: '21 jaar tot pensioenleeftijd - Gehuwd of samenwonend',
            socialMinimum: 0 // TODO
          }
        ]
      };
    },
    computed: {
      socialMinimum() {
        // todo
        return 0;
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>