<template>
  <interview-layout :route="$route" :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="container-narrow w-100">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Dubbelglas</h1>

      <!-- form 1 card -->
      <base-card class="w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">

          <!-- inputs -->
          <base-input
            type="radiobuttons" :options="yesNo"
            name="IsMonument"
            v-model:value="lead.glazing.general.IsMonument" :validator="v$.glazing.general.IsMonument">
            Het het pand de status van Rijksmonument?
          </base-input>
          <issue-feedback :issue="qualStore.sections.glazing.rules.IsMonument" />

          <!-- inputs -->
          <base-input
            type="radiobuttons" :options="accessOptions"
            name="BuildingAccessibleAllround"
            v-model:value="lead.glazing.general.BuildingAccessibleAllround" :validator="v$.glazing.general.BuildingAccessibleAllround">
            Is het pand zowel aan voor- als achterkant goed bereikbaar, tuin niet ingesloten?
          </base-input>
          <issue-feedback :issue="qualStore.sections.glazing.rules.BuildingAccessibleAllround" />

          <!-- inputs -->
          <base-input
            type="radiobuttons" :options="glazingTypeOptions"
            name="CurrentGlazingType"
            v-model:value="lead.glazing.general.CurrentGlazingType" :validator="v$.glazing.general.CurrentGlazingType">
            Welke type beglazing wordt er vervangen?
          </base-input>

          <!-- inputs -->
          <transition name="fade-in-up">
            <base-input
              v-show="lead.glazing.general.CurrentGlazingType"
              type="radiobuttons" :options="glazingAgeOptions"
              name="CurrentGlazingAge"
              v-model:value="lead.glazing.general.CurrentGlazingAge" :validator="v$.glazing.general.CurrentGlazingAge">
              Wanneer is deze beglazing geplaatst?
            </base-input>
          </transition>

          <issue-feedback :issue="qualStore.sections.glazing.rules.GlazingToReplace" />

          <!-- buttons -->
          <button-row class="mt-5">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.glazing.general.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>

      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          { label: 'Ja', value: true },
          { label: 'Nee', value: false },
        ],
        accessOptions: [
          { label: 'Ja, rondom bereikbaar', value: true },
          { label: 'Nee', value: false },
        ],
        glazingTypeOptions: [
          { label: 'Enkel glas', value: 'enkel' },
          { label: 'Dubbel glas', value: 'dubbel' },
        ]
      };
    },
    computed: {
      glazingAgeOptions() {
        let type = this.lead.glazing.general.CurrentGlazingType;
        if (type == 'enkel') {
          return [
            { label: 'Vóór <span class="glazing-year-display">< 1960</span>', value: 'voor-1960' },
            { label: '<span class="glazing-year-display">> 1960</span> of later', value: 'na-1960' },
          ];
        } else if (type == 'dubbel') {
          return [
            { label: 'Vóór <span class="glazing-year-display">< 1995</span>', value: 'voor-1995' },
            { label: '<span class="glazing-year-display">> 1995</span> of later', value: 'na-1995' },
          ];
        }
        return [];
      }
    },
    watch: {
      // clear CurrentGlazingAge when type changes
      'lead.glazing.general.CurrentGlazingType'() {
        this.lead.glazing.general.CurrentGlazingAge = null;
        this.v$.glazing.general.CurrentGlazingAge.$reset();
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.glazing.general.$touch();
        return !this.v$.glazing.general.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
  .glazing-year-display {
    font-weight: 600;
    border: 1px solid $white;
    display: inline-block;
    padding: 5px 10px;
    border-radius: $border-radius;
    letter-spacing: 0.7px;
  }
</style>