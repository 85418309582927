<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">Endoscopisch onderzoek</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Boor nu een gat in de voeg</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card outer-class="w-100" inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <!-- <div class="small mb-4">
                <checkmark-item class="mb-2" icon="exclamation-circle-fill">
                  Boor nu een gat een voeg.
                </checkmark-item>
            </div> -->

            <!-- inputs -->
            <base-input
              type="radiobuttons" :options="yesNo"
              name="Consent"
              v-model:value="lead.cavity_wall.inspection.InsulationPresent" :validator="v$.cavity_wall.inspection.InsulationPresent">
              Ziet u bestaande isolatie in de spouwmuur?
            </base-input>

            <!-- inputs -->
            <transition-group name="fade-in-up">
              <base-input
                v-show="lead.cavity_wall.inspection.InsulationPresent"
                class="mt-5"
                type="buttongrid" :options="insulationOptions"
                name="InsulationMaterial"
                button-grid-input-type="radio"
                :min-col-width="100"
                :max-cols="1"
                btn-direction="row"
                :dim-label-on-value="false"
                :show-input="false"
                v-model:value="lead.cavity_wall.inspection.InsulationMaterial" :validator="v$.cavity_wall.inspection.InsulationMaterial">
                Welk type isolatie ziet u?
              </base-input>

              <base-input
                v-show="lead.cavity_wall.inspection.InsulationPresent === false"
                class="mt-5"
                type="buttongrid" :options="conditionOptions"
                name="CavityCondition"
                button-grid-input-type="radio"
                :min-col-width="100"
                :max-cols="1"
                btn-direction="row"
                :dim-label-on-value="false"
                :show-input="false"
                v-model:value="lead.cavity_wall.inspection.CavityCondition" :validator="v$.cavity_wall.inspection.CavityCondition">
                Hoe ziet de spouwmuur eruit?
              </base-input>
            </transition-group>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.cavity_wall.inspection.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  /* eslint-disable */
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      const imageStyleDebris = {
        height: '100px'
      };
      const imageStyle = {
        height: '100px',
        width: '100px'
      };
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        insulationOptions: [
          {
            label: 'Schuim (UF/PUR)',
            value: 'Schuim',
            image: '/img/cavity-wall/uf-schuim.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          },
          {
            label: 'Reflexiefolie (bv. Alkreflex)',
            value: 'Reflexiefolie',
            image: '/img/cavity-wall/alkreflex.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          },
          {
            label: '(Glas)wol',
            value: 'Wol',
            image: '/img/cavity-wall/glaswol.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          },
          {
            label: 'Parels',
            value: 'Parels',
            image: '/img/cavity-wall/parels.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          },
          {
            label: 'Tempex/EPS platen',
            value: 'EPS platen',
            image: '/img/cavity-wall/eps.jpg',
            imageRounded: true,
            imageStyle: imageStyle
          }
        ],
        conditionOptions: [
          {
            label: 'Schoon, geen vervuiling aanwezig',
            value: 'Schoon',
            icon: 'bi-check-circle'
          },
          {
            label: 'Vervuiling kleiner dan een tennisbal',
            value: 'Licht vervuild',
            image: '/img/cavity-wall/vervuiling-klein-small.jpg',
            imageRounded: true,
            imageStyle: imageStyleDebris
          },
          {
            label: 'Vervuiling op paar plekken, groter dan een tennisbal',
            value: 'Vervuild',
            image: '/img/cavity-wall/vervuiling-tennisbal-small.jpg',
            imageRounded: true,
            imageStyle: imageStyleDebris
          },
          {
            label: 'Op veel plekken vervuild, groter dan een tennisbal',
            value: 'Zwaar vervuild',
            image: '/img/cavity-wall/veel-vervuiling-small.jpg',
            imageRounded: true,
            imageStyle: imageStyleDebris
          }
        
        ]
      };
    },
    computed: {

    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>