<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100">Zonnepanelen</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100" style="width: 960px;">Waar wilt u zonnepanelen plaatsen?</h5>


        <!-- form 1 card -->
        <base-card inner-class="w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <!-- inputs -->
            <button-grid-input
              name="Interests"
              :max-cols="maxCols"
              btn-direction="column"
              type="checkbox"
              btn-class="min-40"
              :pairs-mode="true"
              :distribute-equally="true"
              :options="placementOptions"
              variant="minimal-filled-info"
              inactiveVariant="minimal-dark"
              v-model:value="lead.solar.PlacementLocations">
            Waar wilt u zonnepanelen plaatsen?
            </button-grid-input>
            
          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->

      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.solar.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  // import BaseInput from "@/components/BaseInput.vue"
  import ButtonGridInput from "@/components/ButtonGridInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      // BaseInput,
      BaseButton,
      ButtonRow,
      ButtonGridInput
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        maxCols: null,
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        placementOptions: [
          {
            'label': 'Dak<br><small>met dakpannen</small>',
            'value': 'Pannendak',
            'icon': 'bi-house-roof'
          },
          {
            'label': 'Schuin dak<br><small>met dakbedekking</small>',
            'value': 'Schuin dak met dakbedekking',
            'icon': 'bi-slanted-roof-2'
          },
          {
            'label': 'Plat dak',
            'value': 'Plat dak',
            'icon': 'bi-house-flat-roof-solar-2'
          },
          {
            'label': 'In de tuin',
            'value': 'Tuin',
            'icon': 'bi-flower-field'
          },
          {
            'label': 'Ik heb geen ruimte',
            'value': 'Geen ruimte',
            'icon': 'bi-x',
            'exclusive': true
          }
        ]
      };
    },
    computed: {
    },
    methods: {
      touchAndValidate() {
        this.v$.solar.$touch();
        return !this.v$.solar.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      },
      breakPoints() {
        if (window.innerWidth > 550) {
          this.maxCols = null;
          this.btnDirection = 'column';
        } else {
          this.maxCols = 1;
          this.btnDirection = 'row';
        }
      }
    },
    beforeRouteUpdate() {
      this.breakPoints();
    },
    mounted() {
      this.breakPoints();
      window.addEventListener("resize", this.breakPoints);
    },
    unmounted() {
      window.removeEventListener("resize", this.breakPoints);
    },
    watch: {
      'lead.solar.PlacementLocations': {
        deep: true,
        handler: function(newValue) {
          if (newValue.includes('Geen ruimte') && newValue.length > 1) {
            // remove geen ruimte from array
            this.lead.solar.PlacementLocations = newValue.filter(item => item !== 'Geen ruimte');
          }
        }
      }
    }
  }
</script>

<style lang="scss">
</style>