<template>
  <h5 class="panes-editor_label mb-5">
    <slot></slot>
  </h5>
  
  <div>
    <pane-row v-for="(pane, index) in panes" :key="getKey(index, pane)" :delete-enabled="index !== 0" :pane="pane" @update:pane="updatePane(index, $event)" @delete="deletePane(index)" />
  </div>
  <div class="mt-2 text-sm">
    <base-button variant="minimal-primary" @click="addPane">
      <i class="bi bi-plus"></i>
      Ruit toevoegen
    </base-button>
  </div>
</template>

<script>
import PaneRow from '@/components/PaneRow.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
  components: {
    PaneRow,
    BaseButton
  },
  props: {
    panes: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    updatePane(index, value) {
      let panes = this.panes;
      panes[index] = value;
      this.$emit('update:panes', panes);
    },
    addPane() {
      let panes = this.panes;
      panes.push({
        id: new Date().getTime()
      });
      this.$emit('update:panes', panes);
    },
    deletePane(index) {
      let panes = this.panes;
      panes.splice(index, 1);
      this.$emit('update:panes', panes);
    },
    getKey(index, pane) {
      return pane.id ? pane.id : index;
    }
  }
}
</script>

<style scoped="true" lang="scss">
.panes-editor_label {
  opacity: 1;
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 4px;
  padding-left: $input-border-radius / 2;
  cursor: text;
  transition: opacity 0.5s ease;
  will-change: opacity;
}
</style>