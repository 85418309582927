<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div>
      <!-- title above form card -->
      <h1 class="px-4 max-100">Vloer- &amp; bodemisolatie</h1>
      <h6 class="px-4 pb-3 max-100 text-primary fade-in-up-enter-active animation-slowest delay-200">Aanvullende details</h6>

      <!-- form 1 card -->
      <base-card class="w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">

          <!-- inputs -->
          <base-input
            type="number"
            name="CrawlSpaceHeightCm"
            field-width="200px"
            v-model:value="lead.floor.details.CrawlSpaceHeightCm"
            :validator="v$.floor.details.CrawlSpaceHeightCm"
            :debounce="700">
            <template #default>
            Hoe hoog is de kruipruimte?
            </template>
            <template #after>
              <span class="unit px-2 small fw-600 text-secondary">cm</span>
            </template>
          </base-input>
          <issue-feedback :issue="qualStore.sections.floor.rules.CrawlSpaceHeightCm" />
          
          <base-input
            :disabled="CrawlSpaceHeightCm_int < 50"
            type="buttongrid" :options="insulationOptions"
            name="FloorInsulationSide"
            button-grid-input-type="radio"
            :min-col-width="100"
            :max-cols="maxCols"
            :btn-direction="btnDirection"
            :dim-label-on-value="false"
            v-model:value="lead.floor.details.FloorInsulationSide" :validator="v$.floor.details.FloorInsulationSide">
            {{FloorInsulationSide_label}}
          </base-input>

          <!-- buttons -->
          <button-row class="mt-5">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.floor.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>

      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  import { parseInt } from 'lodash';

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        insulationOptions: [
          {
            label: 'Onder de vloer',
            value: 'Vloer',
            icon: 'bi-chevron-bar-up'
          },
          {
            label: 'Op de bodem',
            value: 'Bodem',
            icon: 'bi-chevron-bar-down'
          },
          {
            label: 'Geen voorkeur',
            value: 'Geen voorkeur',
            icon: 'bi-dash-lg'
          },
        ],
        btnDirection: 'column',
        maxCols: 3,
      };
    },
    computed: {
      CrawlSpaceHeightCm_int() {
        return parseInt(this.lead.floor.details.CrawlSpaceHeightCm ?? null);
      },
      FloorInsulationSide_label() {
        if (this.CrawlSpaceHeightCm_int !== null && this.CrawlSpaceHeightCm_int !== '' && this.CrawlSpaceHeightCm_int < 50) {
          return 'Uw kruipruimte kan geïsoleerd worden middels bodemisolatie.'
        }
        return 'Wilt u isoleren aan de onderkant van de vloer of op de bodem?';
      },
    },
    methods: {
      autoSetFloorInsulationSide() {
        if (this.CrawlSpaceHeightCm_int < 50) {
          this.lead.floor.details.FloorInsulationSide = 'Bodem';
        }
      },
      touchAndValidate() {
        this.v$.floor.details.$touch();
        return !this.v$.floor.details.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      },
      breakPoints() {
        if (window.innerWidth > 500) {
          this.btnDirection = 'column';
          this.maxCols = 99;
        } else {
          this.btnDirection = 'row';
          this.maxCols = 1;
        }
      }
    },
    watch: {
      'lead.floor.details.CrawlSpaceHeightCm'() {
        this.autoSetFloorInsulationSide();
      }
    },
    beforeRouteUpdate() {
      this.breakPoints();
    },
    mounted() {
      this.autoSetFloorInsulationSide();
      this.breakPoints();
      window.addEventListener("resize", this.breakPoints);
    },
    unmounted() {
      window.removeEventListener("resize", this.breakPoints);
    }
  }
</script>

<style lang="scss">
</style>