<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">Bereikbaarheid</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Bereikbaarheid met ladders</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card outer-class="w-100" inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <!-- <div class="mb-4">
                <checkmark-item class="mb-2" icon="exclamation-circle-fill">
                  Meet nu de voeg op.
                </checkmark-item>
            </div> -->

            <!-- inputs -->
            <base-input
              type="buttongrid" :options="ladderAccessOptions"
              name="LadderAccess"
              button-grid-input-type="radio"
              :min-col-width="100"
              :max-cols="1"
              btn-direction="row"
              :dim-label-on-value="false"
              :show-input="false"
              v-model:value="lead.cavity_wall.access.LadderAccess" :validator="v$.cavity_wall.access.LadderAccess">
              Is er een aanbouw of overkapping die toegang per ladder belemmert?
            </base-input>
            <issue-feedback :issue="qualStore.sections.cavity_wall.rules.Access" />

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.cavity_wall.access.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  /* eslint-disable */
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        ladderAccessOptions: [
          {
            label: 'Aanbouw met schuin dak',
            value: 'Aanbouw met schuin dak',
            image: '/img/access/aanbouw-schuin-dak.jpg',
            imageRounded: true,
            imageStyle: {
              width: '100px',
              height: '100px'
            }
          },
          {
            label: 'Schuine overkapping halverwege gevel',
            value: 'Schuine overkapping halverwege gevel',
            image: '/img/access/schuine-overkapping.jpg',
            imageRounded: true,
            imageStyle: {
              width: '100px',
              height: '100px'
            }
          },
          {
            label: 'Aanbouw/overkapping kan geen ladder dragen',
            value: 'Aanbouw/overkapping is niet beloopbaar',
            image: '/img/access/plasticdak.jpg',
            imageRounded: true,
            imageStyle: {
              width: '100px',
              height: '100px'
            }
          },
          {
            label: 'Aanbouw/overkapping kan ladder dragen',
            value: 'Aanbouw/overkapping is beloopbaar',
            image: '/img/access/aanbouw-bitumen.jpg',
            imageRounded: true,
            imageStyle: {
              width: '100px',
              height: '100px'
            }
          },
          {
            label: 'Gevels overal goedbereikbaar met ladder',
            value: 'Geen',
            icon: 'bi-check-circle',
            exclusive: true
          }
        ]
      };
    },
    computed: {

    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    },
    watch: {
      'lead.cavity_wall.ventilation.VentTypes': {
        deep: true,
        handler: function(newValue) {
          if (newValue.includes('Geen') && newValue.length > 1) {
            // remove geen ruimte from array
            this.lead.cavity_wall.ventilation.VentTypes = newValue.filter(item => item !== 'Geen');
          }
        }
      }
    }
  }
</script>

<style lang="scss">
</style>