<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div>
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Vloer- &amp; bodemisolatie</h1>

      <!-- form 1 card -->
      <base-card class="w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">

          <!-- inputs -->
          <base-input
            type="radiobuttons" :options="yesNo"
            name="SpaceBeneathFloor"
            v-model:value="lead.floor.general.SpaceBeneathFloor" :validator="v$.floor.general.SpaceBeneathFloor">
            Is er een (kruip)ruimte aanwezig onder de vloer?
          </base-input>
          <issue-feedback :issue="qualStore.sections.floor.rules.SpaceBeneathFloor" />

          <base-input
            type="radiobuttons" :options="yesNo"
            name="CrawlSpaceAccessible"
            v-model:value="lead.floor.general.CrawlSpaceAccessible" :validator="v$.floor.general.CrawlSpaceAccessible">
            Is deze ruimte bereikbaar via een kruipluik?
          </base-input>
          <issue-feedback :issue="qualStore.sections.floor.rules.CrawlSpaceAccessible" />

          <!-- buttons -->
          <button-row class="mt-5">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.floor.general.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>

      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ]
      };
    },
    methods: {
      touchAndValidate() {
        this.v$.floor.general.$touch();
        return !this.v$.floor.general.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      },
      breakPoints() {
        if (window.innerWidth > 500) {
          this.btnDirection = 'column';
          this.maxCols = 99;
        } else {
          this.btnDirection = 'row';
          this.maxCols = 1;
        }
      }
    }
  }
</script>

<style lang="scss">
</style>