<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">Gevelverf</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Controleer de buitengevel van de woning</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card outer-class="w-100" inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <!-- <div class="small mb-4">
                <checkmark-item class="mb-2">
                  Het Samenwerkingsverband Noord-Nederland (SNN) heeft subsidieregelingen voor woningeigenaren die in het aardbevingsbebied wonen of erkende bevingsschade hebben.
                </checkmark-item>
                <checkmark-item class="mb-2">
                  Middels deze vragen controleren we of u in aanmerking komt voor een aanvraag.
                </checkmark-item>
            </div> -->

            <!-- inputs -->
            <base-input
              type="radiobuttons" :options="yesNo"
              name="WallsPainted"
              v-model:value="lead.cavity_wall.paint.WallsPainted" :validator="v$.cavity_wall.paint.WallsPainted">
              Is buitengevel geverfd?
            </base-input>

            <!-- inputs -->
            <transition name="fade-in-up">
              <base-input
                v-show="lead.cavity_wall.paint.WallsPainted"
                class="mt-5"
                type="radiobuttons" :options="permeablePaintOptions"
                name="VapourPermeablePaint"
                v-model:value="lead.cavity_wall.paint.VapourPermeablePaint" :validator="v$.cavity_wall.paint.VapourPermeablePaint">
                Is de verflaag dampdoorlatend of dampdicht?
              </base-input>
            </transition>
            <issue-feedback :issue="qualStore.sections.cavity_wall.rules.VapourPermeablePaint" />

            <base-button
              variant="minimal-primary"
              class="small"
              icon="bi-info-circle-fill"
              icon-position="start"
              @click="explainerOpen = explainerOpen ? false : true">
              Hoe weet ik of verf dampdoorlatend is?
            </base-button>
            <Transition name="fade-in-up">
              <base-card v-if="explainerOpen" color="light" class="small fw-500 pt-2">
                <h6>Hoe weet ik of verf dampdoorlatend is?</h6>
                <p>
                  Dit is meestal niet direct aan de verf te zien. De beste manier om dit te bepalen is om na te gaan welke verf er precies gebruikt is.
                </p>
                <p class="mb-0">
                  Soms hebben bewoners een verpakking bewaard of een restje staan, vraag hier naar en bekijk indien beschikbaar of er achterop iets vermeld staat als "ademend" of "dampdoorlatend".
                </p>
              </base-card>
            </Transition>
            <Transition name="fade-in-up">
              <base-card v-if="explainerOpen" color="warning" class="small fw-500 pt-2">
                <h6 class="text-warning">Let op bij dikke verflagen</h6>
                <p>
                  Wanneer een woning drie keer geverfd is met een dampdoorlatende verf is de verflaag vaak praktisch dampdicht geworden.
                </p>
                <p class="mb-0">
                  Vraag na bij de bewoners hoe vaak de gevel geverfd is. Wanneer dit drie keer of meer is, geef dan aan dat de verflaag waarschijnlijk dampdicht is.
                </p>
              </base-card>
            </Transition>
          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.cavity_wall.paint.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  // import CheckmarkItem from "@/components/CheckmarkItem.vue"
  import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      // CheckmarkItem,
      IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        permeablePaintOptions: [
          {
            label: 'Dampdoorlatend',
            value: 'Ja'
          },
          {
            label: 'Dampdicht',
            value: 'Nee'
          },
          {
            label: 'Weet ik niet',
            value: 'Weet ik niet'
          }
        ],
        explainerOpen: false
      };
    },
    computed: {

    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>