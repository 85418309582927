<template>
  <base-button
    tag="a"
    :variant="variant"
    :class="classes"
    :icon="buttonIcon"
    icon-position="start"
    :href="lead.meta.ReturnUrl"
    v-if="lead.meta.ReturnUrl"
  >
  {{ buttonLabel }}<br/>
  <small>terug naar uw overzicht</small>
  </base-button>
</template>

<script>
import { useAllStores } from "@/helpers/UseAllStores";
import { useQualificationStore } from '@/store/QualificationStore';

import BaseButton from "@/components/BaseButton.vue";
import ClassList from "@/helpers/ClassList";

export default {
  components: {
    BaseButton
  },
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    wide: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    textAlign: {
      type: String,
      default: 'end'
    },
    buttonLabel: {
      type: String,
      default: 'Afronden'
    },
    buttonIcon: {
      type: String,
      default: 'bi-check-lg'
    }
  },
  setup() {
    let qualStore = useQualificationStore();
    return {...useAllStores(), qualStore};
  },
  computed: {
    classes() {
      let cl = ClassList({
        'btn-lg': true
      });
      cl.addClass('text-' + this.textAlign);
      if (this.wide) {
        cl.addClass('btn-wide');
      }
      if (this.fullWidth) {
        cl.addClass('btn-full-width');
      }
      return cl.getAll();
    }
  }
}
</script>

<style lang="scss" scoped>
  small {
    font-size: 0.8em;
    font-weight: 300;
  }
  .btn-wide {
    min-width: 250px;
  }
  .btn-full-width {
    width: 100%;
  }
</style>