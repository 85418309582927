<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">Voorkeuren</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Hoe mogen wij contact opnemen?</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit" class="d-flex flex-column">

            <!-- inputs -->
            <base-input
              type="textarea"
              name="PreferredContactTime"
              v-model:value="lead.contactPreferences.PreferredContactTime" :validator="v$.contactPreferences.PreferredContactTime" :placeholder="PreferredContactTimePlaceholder">
              Wanneer kunnen we u telefonisch het beste bereiken?
            </base-input>

            <span class="text-muted fst-italic small">* Wij proberen rekening te houden met uw beschikbaarheid, maar dit is niet altijd mogelijk.</span>

            <!-- inputs -->
            <base-input
              type="radiobuttons" :options="yesNo"
              name="SpeaksDutch"
              v-model:value="lead.contactPreferences.SpeaksDutch" :validator="v$.contactPreferences.SpeaksDutch">
              Spreekt u Nederlands?
            </base-input>

            <!-- inputs -->
            <base-input
              v-show="lead.contactPreferences.SpeaksDutch == false"
              type="text"
              name="PreferredOtherLanguage"
              v-model:value="lead.contactPreferences.PreferredOtherLanguage" :validator="v$.contactPreferences.PreferredOtherLanguage" placeholder="Engels, Duits, Turks">
              Welke taal of talen spreekt u liever?
            </base-input>

            <!-- <base-input
              type="buttongrid" :options="days"
              name="ContactOnDays"
              button-grid-input-type="checkbox"
              :min-col-width="100"
              :max-cols="maxCols"
              :btn-direction="btnDirection"
              :dim-label-on-value="false"
              v-model:value="lead.contactPreferences.ContactOnDays" :validator="v$.contactPreferences.ContactOnDays">
              Op welke dagen kunnen we u het beste bereiken?
            </base-input> -->

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.contactPreferences.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  // import CheckmarkItem from "@/components/CheckmarkItem.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      // CheckmarkItem
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        days: [
          {
            label: 'Maandag',
            value: 'Maandag'
          },
          {
            label: 'Dinsdag',
            value: 'Dinsdag'
          },
          {
            label: 'Woensdag',
            value: 'Woensdag'
          },
          {
            label: 'Donderdag',
            value: 'Donderdag'
          },
          {
            label: 'Vrijdag',
            value: 'Vrijdag'
          },
          {
            label: 'Zaterdag',
            value: 'Zaterdag'
          },
          {
            label: 'Zondag',
            value: 'Zondag'
          }
        ],
        PreferredContactTimePlaceholder: `ma-vr tijdens kantoor uren\n's avonds\nGeen voorkeur`
      };
    },
    computed: {
      showHoaContactForm() {
        return (this.lead.ownership.HoaPermission == true && this.lead.ownership.HoaMember == true);
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>