import { isString } from "lodash"

/**
 * wrapper for router.push() method that pushes the old route's params to the new route
 * @param {object} router vue router (this.$router)
 * @param {object} from current route (this.$route)
 * @param {object|string} to same as standard vue-router .push(to) param
 * When the same params are set in both the 'to' object and the current route's params, the 'to' params will overwrite the current params
 * @param {boolean=false} withQuery set to true to copy query params
 */
const PushWithParams = function(router, from, to, withQuery = false) {
  // convert to object if string literal
  if (isString(to)) {
    to = {
      path: to,
    };
  }
  // if old route had params
  if ('params' in from) {
    let oldParams = from.params;
    // if to object already has params set
    if ('params' in to) {
      // new params should overwrite old route params
      let newParams = to.params;
      to.params = { ...oldParams, ...newParams };
    } else {
      to.params = oldParams;
    }
  }
  // if withQuery = true and old route had query params
  if (withQuery && 'query' in from) {
    let oldQuery = from.query;
    // if to object already has params set
    if ('query' in to) {
      // new params should overwrite old route params
      let newQuery = to.query;
      to.query = { ...oldQuery, ...newQuery };
    } else {
      to.query = oldQuery;
    }
  }
  // router.push
  router.push(to);
}

export default PushWithParams;