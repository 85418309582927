<template>
  <interview-layout :show-nav="false">
    <!-- inner wrapper used for centering -->
    <div class="container-narrow w-100">

      <!-- title above form card -->
      <h1 class="px-4 fade-in-up-enter-active animation-slowest max-100 text-danger">Situatie mogelijk ongeschikt</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100"> Bespreek uw situatie met onze adviseur.</h5>

      <!-- issues -->
      <qual-issue-summary :section="qualStore.global" :expand="true" @nav-to-issue="onNavigateToIssueView" />

      <!-- call-request card -->
      <Transition name="fade-in-up">
        <div class="call-request-container px-4 mt-5 ms-auto fade-in-up-enter-active" v-show="busy == false && resultSubmitted">
          <call-request-card />
        </div>
      </Transition>

      <button-row class="mt-4">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
        <return-to-portal-button
          v-if="lead.meta.ReturnUrl"
          button-label="Afsluiten"
          button-icon="bi-x"
          text-align="start"
          variant="secondary" />
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue";
  // import BaseCard from '@/components/BaseCard.vue';
  import BaseButton from "@/components/BaseButton.vue";
  import ReturnToPortalButton from "@/components/ReturnToPortalButton.vue";
  import ButtonRow from "@/components/ButtonRow.vue";
  import QualIssueSummary from "@/components/QualIssueSummary.vue";
  import CallRequestCard from "@/components/CallRequestCard.vue";

  import { useAllStores } from "@/helpers/UseAllStores";
  // import { FlowService } from "@/services/FlowService";
  import { useQualificationStore } from '@/store/QualificationStore';
  import { LeadDataService } from "@/services/LeadDataService";
  import PushWithParams from "@/helpers/PushWithParams";

  // import { filter } from 'lodash';

  export default {
    components: {
      InterviewLayout,
      // BaseCard,
      BaseButton,
      ButtonRow,
      QualIssueSummary,
      ReturnToPortalButton,
      CallRequestCard
    },
    setup() {
      let qualStore = useQualificationStore();
      return {...useAllStores(), qualStore};
    },
    data() {
      return {
        resultSubmitted: false,
        busy: false
      }
    },
    methods: {
      onPrev() {
        this.$router.go(-1)
      },
      onNavigateToIssueView(viewName) {
        PushWithParams(
          this.$router,
          this.$route,
          {
            name: viewName
          },
          true);
      },
    },
    mounted() {
      this.busy = true;
      LeadDataService.pushRemoteData(this.uiStore.uuid, true)
      .then(() => {
        this.resultSubmitted = true;
      })
      .catch(() => {
        this.resultSubmitted = false;
        console.log('Failed to submit result.');
      })
      .then(() => {
        this.busy = false;
      });
    }
  }
</script>

<style scoped lang="scss">
</style>