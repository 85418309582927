<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Woninginformatie</h1>

      <!-- form 1 card -->
      <base-card inner-class="container d-flex" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">

          <!-- inputs -->
          <base-input
            type="number"
            name="ConstructionYear" fieldWidth="200px"
            v-model:value="lead.property.ConstructionYear" :validator="v$.property.ConstructionYear">
            Wat is het bouwjaar van uw woning?
          </base-input>
          
          <div class="d-flex flex-wrap flex-row max-100">

            <base-input
              type="number"
              class="me-5"
              name="GasConsumption" fieldWidth="200px"
              field-background="gray-200"
              v-model:value="lead.property.GasConsumption"
              :validator="v$.property.GasConsumption"
              :do-not-reward="true"
              force-validation-state="warning"
              force-validation-message="Gasverbruik per jaar"
            >
              <template #default>
                <i class="bi bi-fire"></i>
                Wat is uw gasverbruik per jaar?
              </template>
            <template #after>
                <span class="unit px-2 small fw-600 text-secondary">m<sup>3</sup></span>
            </template>
            </base-input>

            <base-input
              type="number"
              class="me-5"
              name="PowerConsumption" fieldWidth="200px"
              field-background="gray-200"
              v-model:value="lead.property.PowerConsumption"
              :validator="v$.property.PowerConsumption"
              :do-not-reward="true"
              force-validation-state="warning"
              force-validation-message="Stroomverbruik per jaar"
            >
              <template #default>
                <i class="bi bi-lightning-fill"></i>
                Wat is uw stroomverbruik per jaar?
              </template>
            <template #after>
                <span class="unit px-2 small fw-600 text-secondary">kWh</span>
            </template>
            </base-input>

          </div>

          <base-input
            type="radiobuttons" :options="houseTypeOptions"
            name="HouseType" direction="column"
            v-model:value="lead.property.HouseType" :validator="v$.property.HouseType" force-validation-state="neutral">
            Welk type huis heeft u?
          </base-input>

          <!-- buttons -->
          <button-row class="mt-5">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.property.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>

      <button-row class="mt-2">
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"

  import { useAllStores } from "@/helpers/UseAllStores";
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ]
      };
    },
    computed: {
      houseTypeOptions() {
        return [
          { label: 'Tussenwoning', value: 'Tussenwoning' },
          { label: 'Hoekwoning', value: 'Hoekwoning' },
          { label: 'Twee onder één kap', value: 'Twee onder één kap' },
          { label: 'Vrijstaande woning', value: 'Vrijstaande woning' },
          { label: 'Geschakelde woning', value: 'Geschakelde woning' },
          { label: 'Appartement', value: 'Appartement' },
          { label: 'Bungalow', value: 'Bungalow' },
          { label: 'Garage', value: 'Garage' },
          { label: 'Anders', value: 'Anders' }
        ];
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.property.$touch();
        return !this.v$.property.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>